<template>
  <div class="char-room-in">
    <!--  v-if="questionData" -->
    <Select v-if="showDrag" :data="questionData" @close="showDrag = false" @submit="questionSubmit" />
    <div id="wrap" class="wrap-class"></div>
    <template>
      <van-dialog v-model="signInVisible" allow-html title="签到" confirm-button-text="签到" @confirm="toSignIn">
        <div v-if="signInData">
          <CountDown :count="signInData.data.limitTime" @onStop="signInVisible = false"/>
          <div class="signin-tip">{{ signInData.data.message }}</div>
        </div>
      </van-dialog>
    
      <!-- <Drag v-if="showDrag">
        <div v-if="questionData">
          <div class="opt-title">{{questionData.title}}</div>
          <div class="drag-options">
            <el-checkbox-group 
              v-if="questionData.type === 'C'"
              v-model="questionList">
              <el-checkbox v-if="questionData.option1" :label="questionData.option1" :key="questionData.option1">{{questionData.option1}}</el-checkbox>
              <el-checkbox v-if="questionData.option2" :label="questionData.option2" :key="questionData.option2">{{questionData.option2}}</el-checkbox>
              <el-checkbox v-if="questionData.option3" :label="questionData.option3" :key="questionData.option3">{{questionData.option3}}</el-checkbox>
              <el-checkbox v-if="questionData.option4" :label="questionData.option4" :key="questionData.option4">{{questionData.option4}}</el-checkbox>
              <el-checkbox v-if="questionData.option5" :label="questionData.option5" :key="questionData.option5">{{questionData.option5}}</el-checkbox>
            </el-checkbox-group>
            <el-radio-group v-if="questionData.type === 'R'" v-model="questionSin">
              <el-radio v-if="questionData.option1" :label="questionData.option1">{{questionData.option1}}</el-radio>
              <el-radio v-if="questionData.option2" :label="questionData.option2">{{questionData.option2}}</el-radio>
              <el-radio v-if="questionData.option3" :label="questionData.option3">{{questionData.option3}}</el-radio>
              <el-radio v-if="questionData.option4" :label="questionData.option4">{{questionData.option4}}</el-radio>
              <el-radio v-if="questionData.option5" :label="questionData.option5">{{questionData.option5}}</el-radio>
            </el-radio-group>
          </div>
          <div class="drag-btn"><el-button type="primary" size="mini" @click="questionConfirm(questionData)">确定</el-button></div>
        </div>
      </Drag> -->
    </template>
  </div>
</template>
<script>
import { createSign } from '@/api/api'
import { bGetToken } from '@/api/api'
import CountDown from './CountDown'
import Select from './Select'
export default {
  name: 'Charroom',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      cssSrc: "https://player.polyv.net/jssdk/polyv-chatroom.min.css",
      vodPlayerJs: 'https://player.polyv.net/jssdk/polyv-chatroom.min.js',
      chatroom: null,
      signInVisible: false,
      // signInData: {EVENT: "STOP_SIGN_IN",checkinId: "f9e28f10-1f63-11eb-9ead-057ac9",data: {roomId: "1940752", timestamp: 1604579988826, sign: "0EA7AE7F096FB63481BFB69A22F88439", socketMsgId: "16045799888282720226"},roomId: "1940752",signNum: 0,total: 2},
      signInData: null,
      bulletinVisible: false,
      questionList: [], // 多选
      questionSin: null,// 单选
      showDrag: false,
      bulletinData: null,
      questionData: null,
    }
  },
  mounted() {
    // this.loadPlayerCSS();
    this.loadPlayerScript(this.getRoomObj);
    // this.$modal.show('qcard',{
    //   draggable:true
    // })
    
  },
  destroyed() {
    if (this.chatroom) {
      // this.chatroom.destroy();
    }
  },
  components: {
    CountDown,
    Select
  },
  methods: {
    questionSubmit(val) {
      this.questionConfirm(val)
    },
    questionConfirm(val) {
      let that = this
      let result = ''
      if(this.questionData.type === 'C') {
        val.forEach((item) => {
          result += item
        })
      } else {
        result = val[0]
      }
      let userInfo = this.$store.state.userInfo
      let socket = this.chatroom.chat.socket
      socket.emit('message', JSON.stringify({
        EVENT: 'ANSWER_TEST_QUESTION',
        roomId: that.chatroom.channelId,
        nick: this.detail.buyUserName,
        userId: userInfo.phone,
        option: result,
        questionId: this.questionData.questionId
      }));
      this.questionList = []
      this.showDrag = false
    },
    sendAns() {
      let socket = this.chatroom.chat.socket
      //ANSWER_TEST_QUESTION
    },
    toSignIn() {
      let that = this
      let userInfo = this.$store.state.userInfo
      let socket = this.chatroom.chat.socket
      socket.emit('message', JSON.stringify({
        EVENT:'TO_SIGN_IN',
        roomId: that.signInData.roomId,
        checkinId: that.signInData.data.checkinId,
        user: {
          nick: that.userInfo.name || `pc-${userInfo.name}`,
          userId: userInfo.phone
        }
      }));
      this.signInVisible = false
      this.signInData = null
    },
    async getRoomObj() {
      const appId = 'fjzfeuje1x'
      let userInfo = this.$store.state.userInfo
			const timestamp = new Date().getTime()
      let params1 = [
        {key: 'appId', val: appId},
        {key: 'channelId', val: this.detail.liveChannelNumber},
        {key: 'timestamp', val: timestamp},
        {key: 'userId', val: userInfo.phone},
        {key: 'role', val: 'viewer'}
      ]
      let signResponse = await createSign(params1)
     
      if(+signResponse.returnCode !== 10001) {
        this.$toast(signResponse.returnMsg)
        return false
      }
      let params = {
        appId: appId,
        timestamp: timestamp,
        sign: signResponse.data.sign,
        channelId: this.detail.liveChannelNumber,
        userId: userInfo.phone,
        role: 'viewer',
      }
      let response = await bGetToken(params)
      if(response.code !== 200) {
        return false
      }
      this.loadCharRoom(response.data)
    },
    loadPlayerScript(callback) {
      // if (!window.polyvPlayer) {
      //   const myScript = document.createElement('script');
      //   myScript.setAttribute('src', this.vodPlayerJs);
      //   myScript.onload = callback;
      //   document.body.appendChild(myScript);
      // } else {
      //   callback();
      // }
      callback();
    },
    // loadPlayerCSS() {
    //   const myCss = document.createElement('link');
    //   myCss.setAttribute('ref', 'stylesheet');
    //   myCss.setAttribute('href', this.cssSrc);
    //   var head = document.getElementsByTagName('head')[0]; 
    //   head.appendChild(myCss);
    // },
    // http://dev.polyv.net/2019/liveproduct/l-api/zbglgn/pdcz/get-chat-token/
    loadCharRoom(obj) {
      let userInfo = this.$store.state.userInfo
      let that = this
      this.chatroom = new PolyvChatRoom({
        roomId: this.detail.liveChannelNumber,
        userId: userInfo.phone,
        nick: this.detail.buyUserName,
        pic: userInfo.headImg,
        token: obj.token,
        mediaChannelKey: obj.mediaChannelKey,
        version: '2.0',
        container: '#wrap',
        width: '100%',
        height: '100%',
        userType: 'student',
        roomMessage: function(data) {
          // TODO
          // data为聊天室socket消息，当有聊天室消息时会触发此方法
          if(data.EVENT === "SIGN_IN") {
            that.signInData = data
            that.signInVisible = true
          }
          if(data.EVENT === "STOP_SIGN_IN") {
            that.signInData = null
            that.signInVisible = false
          }
          if(data.EVENT === 'BULLETIN') {
            that.bulletinVisible = true
            
            that.bulletinData = data
            that.$dialog.alert({
              title: '公告',
              message: that.bulletinData.content,
            })
          }
          // 开始抽奖
          if(data.EVENT === 'LotteryStart') {

          }
          // 结束抽奖
          if(data.EVENT === 'LotteryEnd') {

          }
          // 答题卡
          if(data.EVENT === 'GET_TEST_QUESTION_CONTENT') {
            that.showDrag = true
            
            that.questionData = data
            // itemType 答题类型，1表示快速问答，0表示答题卡
          }
        }
      });
      this.$emit('charRoomInit', this.chatroom)
    }
  }
}
</script>

<style lang="less" scoped>
.char-room-in{
  position: relative;
  height: 100%;
  .qcard{
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: -200px;
    background: #fff;
    z-index: 30;
    border-radius: 5px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border: 1px solid rgb(197, 197, 197);
    .qtitle{
      font-size: 16px;
      text-align: center;
    }
    .qbody{

    }
  }
}
.messagedialog{
  background: none!important;
}
.el-dialog__title{
  display: block;
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: #f6f7fb;
  border-bottom: 1px solid #eceff0;
  border-radius: 6px 6px 0 0;
}
.player-signed .el-dialog__body{

}
.player-signed .el-dialog__body span{
  text-align: center;
  display: block;
}
.drag-btn{
  display: flex;
  justify-content: center;
}
.drag-options{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 200px;
  .dop-item{
    width: 40px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #999;
    text-align: center;
    margin: 10px;
  }
  .active{
    background: blue;
    color: #fff;
  }
}
.wrap-class{
  height: 100%;
}
.signin-tip{
  text-align: center;
  margin-bottom: 0.5rem;
}
</style>