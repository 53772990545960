<template>
  <div class="select">
    <div class="left">
      <p class="options" v-if="data.option1" :class="{'actived' : isCheck(data.option1)}" @click="select(data.option1)">{{data.option1}}</p>
      <p class="options" v-if="data.option2" :class="{'actived' : isCheck(data.option2)}" @click="select(data.option2)">{{data.option2}}</p>
      <p class="options" v-if="data.option3" :class="{'actived' : isCheck(data.option3)}" @click="select(data.option3)">{{data.option3}}</p>
      <p class="options" v-if="data.option4" :class="{'actived' : isCheck(data.option4)}" @click="select(data.option4)">{{data.option4}}</p>
      <p class="options" v-if="data.option5" :class="{'actived' : isCheck(data.option5)}" @click="select(data.option5)">{{data.option5}}</p>
    </div>
    <div class="right">
      <van-button type="info" style="width:1.3rem;" :disabled="isDisable" @click="submit" v-if="!submitSuccess" round size="small">提交</van-button>
      <van-button type="info" style="width:1.3rem;" v-if="submitSuccess" plain round size="small">
        <van-icon name="success" />
        已提交
      </van-button>
      <van-icon @click="close" class="close" name="close" size="30" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      disable: true,
      modle:1 , // 0 单选 1 多选
      selectedList: [],
      submitSuccess: false,
    }
  },
  mounted() {
    if((this.data.type === 'C')) {
      this.modle = 1;
    } else {
      this.modle = 0;
    }
  },
  computed:{
    isCheck() {
      return (item) => {
        return this.selectedList.indexOf(item) !== -1
      }
    },
    isDisable() {
      return this.selectedList.length === 0
    }
  },
  methods: {
    select(item) {
      if(this.modle === 0) {
        this.selectedList = [item]
      } else {
        let index = this.selectedList.indexOf(item)
        if(index === -1) {
          this.selectedList.push(item)
        } else {
          this.selectedList.splice(index,1)
        }
      }
    },
    close() {
      this.$emit('close')
    },
    submit() {
      this.$emit('submit', this.selectedList)
      this.submitSuccess = true
    }
  }
}
</script>

<style lang="less" scoped>
.select{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  font-size: 16px;
  padding: 0.2rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #666;
  .left{
    display: flex;
    .options{
      width: 0.55rem;
      height: 0.55rem;
      border-radius: 50%;
      border: 1px solid #666;
      text-align: center;
      line-height: 0.55rem;
      margin-right: 0.3rem;
    }
  }
  .right{
    display: flex;
    align-items: center;
    .close{
      margin-left: 0.2rem;
    }
  }
}
.actived{
  border: 1px solid #1989fa!important;
  color: #1989fa;
}
</style>