<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-04-07 13:47:28
 * @LastEditTime : 2021-05-14 09:41:03
 * @LastEditors  : XiaoLei
-->
<template>
  <div class="live">
    <!-- <div id="ppt" style="width:500px;height:300px;"></div> -->
    <!-- <div class="live-player">
      <div id="player" class="live-player"></div>
    </div>
    <div class="char-room">
      <Charroom :detail="detail" :userInfo="userInfo" @charRoomInit="initLive" />
    </div> -->
    <iframe id="iframe" :src="src" frameborder="0" allowfullscreen="true" allow="microphone; camera" style="border:0;width:100%;height:100%;over-flow: hidden"></iframe>
  </div>
</template>
<script>
import Charroom from '@/components/live/Charroom'
import { createSign, userBuyName, getBlwParam } from '@/api/api'

export default {
  name: 'LivePlayer',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      vodPlayerJs: 'https://player.polyv.net/livesdk/polyv-live.min.js',
      liveSdk: null,
      userInfo: null,
      chatRoom:null,
      src: ""
    }
  },
  components: {
    Charroom
  },
  mounted() {
    // this.userBuyName()
    getBlwParam({
      id: this.detail.id
    }).then(res => {
      const { userId, ts, sign } = res.data;
      this.src = `https://live.polyv.cn/watch/${this.detail.liveChannelNumber}?hasFrame=1&userid=${userId}&ts=${ts}&sign=${sign}`
    })
  },
  destroyed() {
    if (this.liveSdk) {
      this.liveSdk.destroy();
    }
  },
  methods: {
    async userBuyName(chatRoom) {
      this.chatRoom = chatRoom
      let params = {
        id: this.$route.query.id
      }
      let response = await userBuyName(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.userInfo = response.data
      this.loadPlayerScript(this.loadPlayer);
    },
    initLive(chatRoom) {
      this.chatRoom = chatRoom
      this.loadPlayerScript(this.loadPlayer);
    },
    loadPlayerScript(callback) {
      // if (!window.polyvPlayer) {
      //   const myScript = document.createElement('script');
      //   myScript.setAttribute('src', this.vodPlayerJs);
      //   myScript.onload = callback;
      //   document.body.appendChild(myScript);
      // } else {
      //   callback();
      // }
      callback();
    },
    async loadPlayer() {
			const appId = 'fjzfeuje1x'
			const timestamp = new Date().getTime()
			
      let response = await createSign([
        {key: 'appId', val: appId},
        {key: 'channelId', val: this.detail.liveChannelNumber},
        {key: 'timestamp', val: timestamp},
      ])
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      let userInfo = this.$store.state.userInfo
			let opt = {
				channelId: this.detail.liveChannelNumber,
				sign: response.data.sign, // 频道验证签名
				timestamp: timestamp, // 毫秒级时间戳
        appId: appId, // polyv 后台的appId
        socket: this.chatRoom.chat.socket,
				user: {
					userId: userInfo.phone,
					userName: this.detail.buyUserName,
					pic: userInfo.headImg
				}
			}
			this.liveSdk = new PolyvLiveSdk(opt);
			this.liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
				this.liveSdk.setupPlayer({
					// pptEl: '#ppt',
					el: '#player',
					type: 'auto'
				});
      }); 
    }
  }
}
</script>

<style lang="less" scoped>
.live{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .live-player{
    width: 100vw;
    height: 56.25vw;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .char-room{
    width: 100vw;
    height: calc(100vh - 56.25vh);
    background: #fff;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
</style>