<template>
	<div class="app-page-3">
    <!-- <div class="class-title">
      
    </div> -->
    <!-- <div class="app-content" style="padding-bottom:48px;"> -->
      <!-- <div v-if="detail" class="top-title">
        {{detail.courseName}}
      </div> -->
      <!-- <div id="ppt" style="width:500px;height:300px;"></div>
      <div id="player" style="width:500px;height:300px;"></div> -->
      <VideoPlayer v-if="showVideoPlayer && detail && courseGroupDetail" :detail="detail" :courseGroupDetail="courseGroupDetail" />
      <LivePlayer v-if="showLivePlayer" :detail="detail" />
      
    <!-- </div> -->
	</div>
</template>

<script>
import { LOOP_TIME } from '@/config/config.js'
import { courseDetailWeb, getCourseGroupDetail, getUserInfo } from '@/api/api'
import VideoPlayer from '@/components/live/VideoPlayer'
import LivePlayer from '@/components/live/LivePlayer'

export default {
	name: "class",
	data() {
		return {
      timeId: null,
      timer: null,
			liveSdk: null,
			detail: null,
			showVideoPlayer: false,
			showLivePlayer: false,
      // showCharroom: false,
      courseGroupId: null,
      courseGroupDetail: null,
      ws: null,
		}
  },
  middleware: 'accountVerification',
	mounted() {
    // this.tokenTip('???')
		this.id = this.$route.params.id || null
    this.courseDetailWeb(this.id)
    this.courseGroupId = this.$route.query.id || null
    this.getCourseGroupDetail(this.courseGroupId)
    if(this.getUserInfo1().token) {
      // this.webSocket()
      this.loopQuery()
    } else {
      this.$router.replace('/course')
    }
	},
	components: {
		VideoPlayer,
		LivePlayer,
  },
  beforeDestroy() {
    clearTimeout(this.timeId)
    clearTimeout(this.timer)
  },
	methods: {
    loopQuery() {
      this.timer = setInterval(async () => {
        let response = await getUserInfo()
      }, LOOP_TIME)
      
    },
    getClassType(str = "") {
      return str.split(' | ')[2]
    },
    // 获取课程组详情
    async getCourseGroupDetail(id) {
      let params = {
        id: id
      }
      let response = await getCourseGroupDetail(params)
      if(+response.returnCode !== 10001) {
        this.$toast('获取课程列表失败')
        return false
      }
      this.courseGroupDetail = response.data
    },
		// 获取课程详情
		async courseDetailWeb(id) {
			let params = {
        id: id,
			}
			let response = await courseDetailWeb(params)
			if(+response.returnCode !== 10001) {
				this.$toast(response.returnMsg)
				return false
      }
      // TODO
      // 判断是否已经购买，如果为购买，就跳转到课程详情
			this.detail = response.data
			if(response.data.liveStatus === 2) {
				// this.initLive(response.data)
				this.showLivePlayer = true
				// this.showCharroom = true
			} else if (response.data.liveStatus === 3 || response.data.liveStatus === 5) {
				this.showVideoPlayer = true
			}
    },
    getUserInfo1() {
      if(this.$store.state.userInfo) {
        return this.$store.state.userInfo
      } else {
        return {}
      }
    },
    delayReload() {
      this.timeId = setTimeout(() => {
        window.location.reload()
      }, 10000)
    },
    tokenTip(tip = "账号已在其他地方登录") {
      this.delayReload()
      this.$dialog.alert({
        message: tip,
      }).then(() => {
        this.$store.commit('user/logout')
        window.location.reload()
      });
      // this.$alert(tip, '提示', {
      //   confirmButtonText: '重新登录',
      //   closeOnClickModal: false,
      //   closeOnPressEscape: false,
      //   showCancelButton: false,
      //   showClose: false,
      //   type: 'error',
      //   callback: action => {
          
      //   }
      // });
    },
    webSocket() {
      let that = this
      if("WebSocket" in window) {
        let ws = new WebSocket("ws://47.97.9.83:8447/learned/validationUser");
        ws.onopen = (evt) => { 
          let params = {
            "status":"2001",
            "phone": this.getUserInfo1().phone,
            "token": this.getUserInfo1().token
          }
          ws.send(JSON.stringify(params));
        };
        ws.onmessage = (evt) => {
          // let response = JSON.parse(evt)
          if(evt) {
            try {
              let response = JSON.parse(evt.data)
              if(+response.status === 999998) {
                that.$store.commit('user/logout')
                that.tokenTip()
              }
            } catch (error) {
              
            }
          }
        };
      } else{
        //console.log("您的浏览器不支持WebSocket");
      }
    },
    // wsOpen() {
    //   console.log(111)
    //   this.ws.send("hello");
    // }
	}
}
</script>

<style lang="less" scoped>
.class-title{
  
  background: #333333;
  width: 100%;
  .class-title-content{
    height: 44px;
    width: 1200px;
    margin: 0 auto;
    color: #999999;
    font-size: 14px;
    display: flex;
    align-items: center;
    a{
      text-decoration:none!important;
    }
    span{
      color: #999999;
      text-decoration:none!important;
    }
  }
}
.top-title{
  height: 80px;
  line-height: 80px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  // padding-top: 34px;
}
</style>